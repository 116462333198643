.Background {
  flex-grow: 1;
  height: 175vh;
  display: flex;
  flex-direction: row;
}

.ContentColumn {
  margin: auto;
  width: 55vw;
  height: 175vh;
  transition: width 0.3s ease;
}


@media (max-width: 1024px) {
  .ContentColumn {
    width: 65vw;
  }
}


@media (max-width: 768px) {
  .ContentColumn {
    width: 80vw;
  }
}


@media (max-width: 480px) {
  .ContentColumn {
    width: 90vw;
  }
}
