.JobLocation{
    margin-top: 0.5%;
    font-weight: 400;
    font-size: 14px;
    color: rgb(102 102 102);
}

.CompanyTitle, .JobTitle{
    padding: 0;
    margin: 0;
    color: #333333;
}

.CompanyTitle{
    font-size: 20px;
    font-weight: 500;
}

.JobTitle{
    font-size: 16px;
    font-weight: 500;
}

.JobText{
    color: rgb(102 102 102);
}

.Container{
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.JobInfo{
    height: 72px;
}

.ImageContainer{
    width: auto;
    height: auto;
}

.Image{
    width: 71.2px;
    height: 71.2px;
    border-radius: 5px;
    margin-right: 20px;
}