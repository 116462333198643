.DocumentHolder{
    width: 78%;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 0px;
}

.DocumentsTitle{
    margin-left: 11%;
    padding: 0;
    color: #333333;
    margin-top: 40px;
}

.DocumentsSub{
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
}

.LeftSide{
    width: 50%;
}

.RightSide{
    width: 50%;
}

.Document{
    color: rgb(14, 141, 226);
    text-decoration: none;
    white-space: pre;
}

.DocumentRef{
    color: rgb(102, 102, 102);
}

.MainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}
