.ContactContainer {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
  
  .ContactContainer h1 {
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  .ContactForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .FormGroup {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .FormGroup label {
    margin-bottom: 8px;
    font-size: 1.1rem;
    color: #555;
  }
  
  .FormGroup input, .FormGroup textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .FormGroup textarea {
    min-height: 100px;
  }
  
  .SubmitButton {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .SubmitButton:hover {
    background-color: #45a049;
  }
  
  .SuccessMessage {
    font-size: 1.5rem;
    color: green;
  }
  
  .ErrorMessage {
    font-size: 1.2rem;
    color: red;
  }
  