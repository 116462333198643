.Project{
    width: 85%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    color: rgb(102, 102, 102);
    box-shadow: 0px 1px 2px rgb(102 102 102);
}


.ProjectName{
    color: rgb(65, 131, 196);
    padding-left: 20px;
}

.ProjectText{
    padding-left: 20px;
    padding-right: 20px;
}

.TextHolder{
    width: 100%;
}

.ViewContainer{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 20px;
}

.ProjectView{
    padding: 0;
    margin: 0;
    margin-right: 5%;
    font-size: 20px;
    color: rgb(65, 131, 196);
    text-decoration: none;
}



.ProjectView:hover{
    text-decoration: underline;
    cursor: pointer;
}

.SkillHold{
    
}