.Navholder {
    width: 78%;
    height: 5vh;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
}

.LinkElement {
    font-weight: 400;
    font-size: 20px;
    margin-right: 15px;
    text-decoration: none;
    color: black;
    transition: color 0.3s ease, font-weight 0.3s ease, text-decoration 0.3s ease;
}

.LinkElement:hover {
    cursor: pointer;
    text-decoration: underline;
    color: gray;
}

.LinkElement:focus {
    text-decoration: underline;
    font-weight: bold;
}

.Active {
    font-weight: bold;
    text-decoration: underline;
    transition: font-weight 0.3s ease, text-decoration 0.3s ease, color 0.3s ease;
}
