.Cardholder{
    margin: auto auto auto auto;
    width: 90%;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    box-shadow: 0px 1px 2px rgb(102 102 102);
    flex-wrap: wrap;
}

.MainIntro{
    padding-left: 10px;
    margin-top: 1%;
    margin-bottom: 2px;
}

.TextHolder{
    flex-wrap: wrap;
}

.SubIntro{
    padding-left: 10px;
    margin-top: 0.5%;
    margin-bottom: 1%;
    color: rgb(102 102 102);
    font-weight: 400;
    font-size: 22px;
}

.LocationIntro{
    padding-left: 10px;
    margin-top: 0.5%;
    font-weight: 400;
    font-size: 14px;
    color: rgb(102 102 102);
}


.ProfileImage{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto 10px auto 30px;
    padding: 5px;
}

.LinkElement{
    text-decoration: none;
    font-size: 16px;
    color: rgb(14, 141, 226);
}

.LinkElement:hover{
    text-decoration: underline;
}
