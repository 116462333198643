.Container{
    margin: auto;
    width: 78%;
    height: 10vh;
}

.TitleContainer{
    width: 78%;
    margin: auto;
}

.Title{
    color: #333333;
    margin-top: 40px;
}
