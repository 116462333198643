.Skill{
    display: inline-flex;
    height: 22px;
    border-radius: 10px;
    background-color: rgb(242, 242, 242);
    color: rgb(102, 102, 102);
    margin-left: 2%;
    margin-right: 2%;
    padding-right: 10px;
}

.SkillName{
    padding: 0;
    margin: 0;
    margin-left: 5%;
    white-space: nowrap;
}

.Image{
    scale: 0.8;
}